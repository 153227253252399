import React, { useState } from "react";
import { List, Typography } from 'antd';

import {
    Table,
    Space,
    useTable,
    Button,
    DateField,
    useModalForm,
    Modal, Form,
} from "@pankod/refine-antd";
import { ICompany, VaFundingBank } from "interfaces";
import { CreateDecentroAccountModel } from "../models/create-decentro-account.model";
import { RecalculateBalancesOfVirtualAccountsModel } from "../models/recalculate-va-balance.model";
import { CreateNewRepaymentVaButtonModel } from "../models/create-new-repayment-va.model";
import { CreateEaseBuzzWireVaModel } from "../models/create-eb-wire-va.model";

const { Title, Text } = Typography;

interface VAProp {
    companyDetail: ICompany
    refetchCompanyDetail: (shouldRefetch: boolean) => void;
}

export const VaListingTable: React.FC<VAProp> = ({ companyDetail, refetchCompanyDetail }) => {
    let [tableRefetch, setTableRefetch] = useState(false);
    const [bankAccountData, setBankAccountData] = useState('');
    const [bankAccountId, setBankAccountId] = useState('');
    const [bankAccountStatus, setBankAccountStatus] = useState(false);


    let { tableProps }: any = useTable({
        dataProviderName: "grapghQl",
        resource: 'allVirtualAccountsForCompanyViaInternalPortalPaginated',
        syncWithLocation: true,
        metaData: {
            companyId: companyDetail.id,
            excludeNonActiveBankAccounts: false,
            tableRefetch: tableRefetch,
        },
    });

   const { 
        modalProps: deactivateOrActivateVAAccount, 
        formProps: formProp,
        show: deactivateOrActivateVAAccountShow, 
        close: deactivateOrActivateVAAccountClose 
    } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "deactivateOrActivateVaForCompany",
        action: "create",
        metaData: { 
            bankAccountId: bankAccountId,
            isActive: !bankAccountStatus,
        },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
            setSelectedAccountData({})
            setTableRefetch(true)
        }
    });
    deactivateOrActivateVAAccount.title = 'Update Kodo VA Account Status'
    deactivateOrActivateVAAccount.centered = true
    deactivateOrActivateVAAccount.width = 400
    deactivateOrActivateVAAccount.onCancel = () => {
        setSelectedAccountData({})
        deactivateOrActivateVAAccountClose();
    }

    const setSelectedAccountData = (data) => {
        setBankAccountId(data.id || '')
        setBankAccountStatus(data.isActive || '')
        setBankAccountData(data?.serviceProviderIntegration?.serviceProviderIntegrator?.name || '')
    }

    return (
        <>
            <List >
                <List.Item>
                    <Space align="center">
                        <Title level={5}> Kodo VA Accounts </Title>
                        <CreateNewRepaymentVaButtonModel refetchCompanyDetail={refetchCompanyDetail} companyId={companyDetail.id} />
                    </Space>
                    <RecalculateBalancesOfVirtualAccountsModel refetchCompanyDetail={refetchCompanyDetail} companyId={companyDetail.id} />
                </List.Item>
            </List>

            <Table {...tableProps } rowKey="id">
                <Table.Column
                    title="Integration"
                    dataIndex="bankAccount"
                    render={(_, record: any) => {
                        const integratorName = record?.serviceProviderIntegration?.serviceProviderIntegrator?.name;
                        return (
                            <Space>
                                <Text> {integratorName || '-'}</Text>
                            </Space>
                        );
                    }}
                />
                <Table.Column title="Account Number" dataIndex="accountNo" />
                <Table.Column title="IFSC" dataIndex="ifsc" />
                <Table.Column title="Balance" dataIndex="balance" />
                <Table.Column 
                    title="Added by" 
                    dataIndex="activatedBy" 
                    render={(value: any) => (
                        value.displayName
                    )} />
                <Table.Column
                    title="Added on"
                    dataIndex="activatedAt"
                    render={(value: any) => (
                        <Space>
                            
                            {
                                <DateField className="ml-2" value={value} format="LL" />
                            }
                        </Space>
                    )}
                />
                <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, element: VaFundingBank) => (   
                        <Button danger={element.isActive} onClick={() => { setSelectedAccountData(element); deactivateOrActivateVAAccountShow() }} type="primary">
                            {element.isActive ? 'Deactivate' : 'Reactivate'}
                        </Button>
                    )}
                />
            </Table>

            <CreateDecentroAccountModel refetchCompanyDetail={refetchCompanyDetail} companyId={companyDetail.id} />
            <CreateEaseBuzzWireVaModel refetchCompanyDetail={refetchCompanyDetail} companyId={companyDetail.id} />

            <Modal {...deactivateOrActivateVAAccount}>
                <>{bankAccountStatus ? 'Disable': 'Enable'} the {bankAccountData} Kodo Va Account?</>
                <Form {...formProp} layout="vertical">
                    <>You are going to disable a {bankAccountId} account. Are you Sure?</>
                </Form>
            </Modal>
        </>
    );
};
