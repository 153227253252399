import { gql } from '@apollo/client';

export const getCompanyListsGQL = gql`
  query getCompaniesForManagementPortal {
    getCompaniesForManagementPortal(filter: {}) {
      page
      company {
        borrowerCategory
        nonDeletedPBIsSortedByDate {
          creditLimit
          displayName
          companyProductEnabled {
            id
            cards
          }
          productsBundle {
            id
            displayName
          }
        }
        id
        registeredName
        borrowerCategory
        cin
        emailId
        brandName
        companyLimit
        companyBalance
        isActive
        companyVerification
        isNachActive
        nameOnCard
      }
    }
  }
`;
export const getCompanyClassificationGQL = gql`
  query getCompnayType {
    getCompanyClassification {
      id
      displayName
      name
      typeId
    }
  }
`;

export const getCompaniesForManagementPortalPaginatedGQL = gql`
  query getCompaniesForManagementPortalPaginated(
    $page: Int!,
    $limit: Int!,
    $filter: CompanyFilter
  ) {
    getCompaniesForManagementPortalPaginated(
      filter: $filter,
      pagination: {page: $page, limit: $limit}
    ) {
      items{
        nonDeletedPBIsSortedByDate {
          creditLimit
          displayName
          companyProductEnabled {
            loans
            cards
          }
          productsBundle {
            displayName
          }
        }
        id
        registeredName
        disableStatementGeneration
        cin
        emailId
        brandName
        companyLimit
        createdAt
        companyBalance
        companyCustomerId
        borrowerCategory
        isActive
        companyVerification
        isNachActive
        nameOnCard
      },
      meta {
        totalItems
      }
    }
  }
`;
export const getCompaniesForManagementPortalGQL = gql`
  query getCompaniesForManagementPortal($filter: CompanyFilter) {
    getCompaniesForManagementPortal(filter: $filter) {
    company {
      id
      borrowerCategory
      primaryAccountManagerUser {
        id
        managerId
        manager {
         	firstName
        	lastName
        }
      }
      nameOnCard
      gstNo
      brandName
      registeredName
      emailId
      phoneNo
      panNo
      companyVerification
      bank {
          id
          accountName
          accountNumber
          mobileNo
          ifsc
          bankName
      }
      companyData {
          id
          addressLine1
          addressLine2
          city
          state
          pincode
          directors {
            id
            isSelected
            dob
            firstName
            middleName 
            lastName
            isEdited
          }
      }
      repaymentAutoDebitAuth {
        rzpInvoiceId
        rzpMandateRegUrl
        mandateMaxAmountPerCharge
      }
      nonDeletedPBIsSortedByDate {
        id
        displayName
        repaymentAutoDebitRule {
          id
          amountForFixedChargeCalculationMethod
          chargeCalculationMethod
          frequencyOfFixedIntervalCharge
        }
        productsBundle {
          id
          displayName
          description
          cardIntegration {
            id
            description
            printCompanyNameOnCard
            availableForNewCardIssuance
            cardNetwork
            tenant
          }
          cardIntegrations {
            id
            description
            printCompanyNameOnCard
            availableForNewCardIssuance
            cardNetwork
            tenant
          }
          productEnabled {
            cards
            loans
            payLater
            prepaidCards
          }
        }
        availableLimit
        creditLimit
        sanctionedCreditLimit {
          id
          creditLimit
          monthsApprovedFor
        }
      }
      pbisPendingActivation {
        id
        productsBundle {
          id
          displayName
          productEnabled {
            cards
            prepaidCards
          }
        }
      }
      productsBundleIssuances {
        id
        availableLimit
        displayName
        sharedPayLaterBeneficiaryId
        sharedPayLaterBeneficiary {
          id
          fullName
        }
        productsBundle {
          id
          description
          displayName
          cardIntegrations {
            id
            description
            printCompanyNameOnCard
            availableForNewCardIssuance
            cardNetwork
          }
          productEnabled {
            cards
            loans
            payLater
            prepaidCards
          }
        }
      }
      productsBundleIssuance {
        id
        availableLimit
        creditLimit
        sharedPayLaterBeneficiaryId
        sharedPayLaterBeneficiary {
          id
          fullName
        }
        productsBundle {
          id
          description
          displayName
          cardIntegrations {
            id
            description
            printCompanyNameOnCard
            availableForNewCardIssuance
          }
        }
        companyProductEnabled {
          cards
          loans
          prepaidCards
          payLater
        }
        availableLimit
      }
      featureFlags {
        productFeatureApprovalRequest
        dynamicWorkflow
      }
      companySettings {
        id
        settings {
          isProjectRequiredForKodoPay
          isApprovalRequestReasonRequired
          isDepartmentRequiredForApprovalWorkflow
          isSubDepartmentRequiredForApprovalWorkflow
          isDesignationRequiredForApprovalWorkflow
          isEmployeeGradeRequiredForApprovalWorkflow
          isEmployeeCategoryRequiredForApprovalWorkflow
          isLocationRequiredForApprovalWorkflow
          isProjectRequiredForApprovalWorkflow
          isReportingManagerRequiredForApprovalWorkflow
        }
      }
    }
  }
}
`;

export const updateCompanyDetailGQL = gql`
  mutation updateCompanyDetail(
    $companyId: String!
    $panNo: String
    $emailId: String
    $phoneNo: String
  ) {
    updateCompanyDetail(input: [{
        companyId: $companyId
        panNo: $panNo
        emailId: $emailId
        phoneNo: $phoneNo
      }]){
        updated
        skipped
    }
  }
`

export const companyBankGQL = gql`
  query getCompaniesForManagementPortal($filter: CompanyFilter) {
    getCompaniesForManagementPortal(filter: $filter) {
    company {
      id
      bank {
          id
          accountName
          accountNumber
          mobileNo
          ifsc
          bankName
      }
    }
  }
}
`;

export const createNewRepaymentVaViaInternalPortalGQL = gql`
  mutation createNewRepaymentVaViaInternalPortal($companyId: String!,$sendEmailNotificationToCompanyAdmin: Boolean) {
    createNewRepaymentVaViaInternalPortal(
      companyId: $companyId, 
      sendEmailNotificationToCompanyAdmin: $sendEmailNotificationToCompanyAdmin
    ) 
  }
`;

export const createDecentroVirtualAccountForCompanyGQL = gql`
  mutation createDecentroVirtualAccountForCompany(
    $companyId: String!
  ) {
    createDecentroVirtualAccountForCompany(input: {
        companyId: $companyId
      }){
        id
        accountNo
        nickname
        ifsc
        upiHandle
        balance
        balanceLastUpdatedAt
    }
  }
`

export const createEasebuzzMerchantVirtualAccountForCompanyGql = gql`
mutation createEasebuzzMerchantVirtualAccountForCompany($input: CreateEasebuzzMerchantVirtualAccountInput!) {
    createEasebuzzMerchantVirtualAccountForCompany(input: $input) {
        id
    }
}
`


export const updateCompanyFeaturesAndSettingsGQL = gql`
  mutation updateCompanyFeaturesAndSettings(
    $input: UpdateCompanyFeaturesAndSettingsInput!
  ) {
    updateCompanyFeaturesAndSettings(input: $input)
  }
`;


export const recalculateBalancesOfVirtualAccountsViaInternalPortalGQL = gql`
  mutation recalculateBalancesOfVirtualAccountsViaInternalPortal(
    $companyId: String!
  ) {
    recalculateBalancesOfVirtualAccountsViaInternalPortal(
      companyId: $companyId
    )
  }
`

export const repaymentAutoDebitPaginatedGQL = gql`
  query repaymentAutoDebitPaginated($filter: AutoDebitFilter,$pagination: PaginationOptions!) {
    repaymentAutoDebitPaginated(filter: $filter, pagination:$pagination) {
      items{
        failureReason
        id
        lastUpdateNotes
        mandateMaxAmountPerCharge
        method
        createdAt
        rzpCustomerId
        rzpInvoiceId
        rzpMandateRegUrl
        rzpOrderId
        rzpTokenId
        status
        companyId
        company {
            brandName
            nameOnCard
            registeredName
            id
        }
      }
      meta {
        totalItems
      }
    }
  }
`;

export const changeProductsBundleIssuanceToNewCardTypeGQL = gql`
  mutation changeProductsBundleIssuanceToNewCardType($companyId: String!, $pbiId: String!, $cardType: CardTypeForApplication!) {
    changeProductsBundleIssuanceToNewCardType(companyId: $companyId, pbiId: $pbiId, cardType: $cardType) {
      id
    }
  }
`;

export const renameCompanyGQL = gql`
mutation renameCompany(
  $companyId: String!, 
  $newCompanyName: String!, 
  $nameOnCard: String,
){
    renameCompany(
        companyId:$companyId
        newCompanyName:$newCompanyName
        nameOnCard:$nameOnCard
    )
}
`;
export const assignCompanyAccountManagerGQL = gql`
mutation assignCompanyAccountManager(
  $id: String
  $companyId: String!
  $accountManagerUserId: String!
) {
  assignCompanyAccountManager(input: {
    id: $id,
    companyId: $companyId,
    accountManagerUserId: $accountManagerUserId
  }) {
    id
    isPrimary
    customerId
    managerId
  }
}
`;
export const registerM2pCorporateGQL = gql`
mutation registerM2pCorporate(
  $companyId: String!
  $tenantKey: String!
  $cardIntegrationId: String!
  $onboardingData: M2pCorporateOnboardingData!
) {
  registerM2pCorporate(input: {
    companyId: $companyId,
    tenantKey: $tenantKey,
    cardIntegrationId: $cardIntegrationId,
    onboardingData: $onboardingData
  })
}
`;

export const setM2pCorporateEnabledAtGQL = gql`
  mutation setM2pCorporateEnabledAt($kitNo: String!, $companyId: String!) {
    setM2pCorporateEnabledAt(input: { kitNo: $kitNo,companyId: $companyId })
  }
`;

export const getUsersGQL = gql`
  query getUsers($filter: UserDetails) {
    getUsers(filter: $filter) {
      user {
        id
        firstName
        middleName
        lastName
        fullName
        displayName
        emailId
        emailVerified
        panNo
        mobileNo
        mobileVerified
        profilePicture
        gender
        kycStatus
        rolesToDisplay
        kycDocumentsUploadedForCardholder
        userAccounts {
          id
          status
          companyMappingList {
            id
            companyId
          }
        }
        company {
          id
          registeredName
          companyLimit
          companyVerification
          productsBundleIssuance {
            id
            sharedPayLaterBeneficiaryId
            companyProductEnabled {
              prepaidCards
            }
          }
          companyData {
            id
            addressLine1
            addressLine2
            state
            city
            pincode
          }
        }
        dob
        deliveryAddress {
          id
          addressLine1
          addressLine2
          addressLine3
          city {
            id
            name
          }
          state {
            id
            name
          }
          cityName
          stateName
          pincode
        }
        addresses {
          id
          addressLine1
          addressLine2
          addressLine3
          city {
            id
            name
          }
          state {
            id
            name
          }
          cityName
          stateName
          pincode
        }
        card {
          id
          endNo
          issueDateForVirtualForm
          issueDateForPhysicalForm
          activationNotificationSentAt
          cardActivatedByIntegratorAt
          physicalFormRequestedAt
          cardActivatedInternallyAt
          fundsSource
          allocatedFundsUnused
          isLocked
          isBlocked
          label
          monthlyLimit
          dailyLimit
          kit {
            id
            kitNo
            cardIntegration {
              cardNetwork
              id
            }
          }
          pbiId
        }
        version
        m2pCustomerId
        kycMode
        expectedKycModeForIssuingCard
        pbiMappings {
          id
          pbiId
          pbi {
            id
            companyProductEnabled {
              prepaidCards
            }
            productsBundle {
              id
              description
              displayName
              cardIntegrations {
                id
                description
                printCompanyNameOnCard
                availableForNewCardIssuance
              }
            }
          }
        }
      }
    }
  }
`;
export const doFinalCardActivationGQL = gql`
  mutation doFinalCardActivation($cardholderUserId: String!, $issuePhysicalCard: Boolean, $pbiId: String!, $cardId: String!) {
    doFinalCardActivation(input: { cardholderUserId: $cardholderUserId, pbiId: $pbiId, issuePhysicalCard: $issuePhysicalCard, cardId: $cardId }) {
      displaySendEmailButton
      activationNotificationSentAt
    }
  }
`;
export const issuePhysicalFormOfActiveCardGQL = gql`
  mutation issuePhysicalFormOfActiveCard($cardholderUserId: String!, $pbiId: String!, $cardId: String!) {
    issuePhysicalFormOfActiveCard(cardholderUserId: $cardholderUserId, pbiId: $pbiId, cardId: $cardId) {
      kitNo
      issueDateForVirtualForm
      issueDateForPhysicalForm
      physicalFormRequestedAt
      cardActivatedInternallyAt
    }
  }
`;
export const deleteUserGQL = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(deleteUser: {userId: $userId})
  }
`;

export const deactivateUserAccountGQL = gql`
  mutation deactivateUserAccount($userAccountId: String!,$notes: String) {
    deactivateUserAccount(input: {userAccountId: $userAccountId,notes: $notes})
  }
`;

export const pendingPaymentAndAssociatedCompanyListForUserGQL = gql`
  query pendingPaymentAndAssociatedCompanyListForUser($userAccountId: String!,$companyId: String!) {
    pendingPaymentAndAssociatedCompanyListForUser(userAccountId: $userAccountId,companyId: $companyId) {
      pendingPaymentCount,
      userCompanyList {
          company {
            id
            registeredName
            companyLimit
            brandName
          }
        }
      }
    }
`;
export const searchUserByEmailGQL = gql`
  query searchUserByEmail($emailId: String!) {
    searchUserByEmail(emailId: $emailId) {
      id
      registeredName
      brandName
    }
  }
`;
export const adduserGQL = gql`
  query getUsers($filter: UserDetails) {
    getUsers(filter: $filter) {
      user {
        id
        firstName
        middleName
        lastName
        fullName
        displayName
        emailId
        emailVerified
        panNo
        mobileNo
        mobileVerified
        profilePicture
        gender
        kycStatus
        rolesToDisplay
        kycDocumentsUploadedForCardholder
        userAccounts {
          id
          status
        }
        company {
          id
          registeredName
          companyLimit
          companyVerification
          productsBundleIssuance {
            id
            sharedPayLaterBeneficiaryId
            companyProductEnabled {
              prepaidCards
            }
          }
          companyData {
            id
            addressLine1
            addressLine2
            state
            city
            pincode
          }
        }
        dob
        deliveryAddress {
          id
          addressLine1
          addressLine2
          addressLine3
          city {
            id
            name
          }
          state {
            id
            name
          }
          cityName
          stateName
          pincode
        }
        addresses {
          id
          addressLine1
          addressLine2
          addressLine3
          city {
            id
            name
          }
          state {
            id
            name
          }
          cityName
          stateName
          pincode
        }
        card {
          id
          endNo
          issueDateForVirtualForm
          issueDateForPhysicalForm
          activationNotificationSentAt
          cardActivatedByIntegratorAt
          physicalFormRequestedAt
          cardActivatedInternallyAt
          fundsSource
          allocatedFundsUnused
          isLocked
          isBlocked
          label
          monthlyLimit
          dailyLimit
          kit {
            id
            kitNo
            cardIntegration {
              cardNetwork
            }
          }
          pbiId
        }
        version
        m2pCustomerId
        kycMode
        expectedKycModeForIssuingCard
        pbiMappings {
          id
          pbiId
          pbi {
            id
            companyProductEnabled {
              prepaidCards
            }
            productsBundle {
              id
              description
              displayName
              cardIntegrations {
                id
                description
                printCompanyNameOnCard
                availableForNewCardIssuance
              }
            }
          }
        }
      }
    }
  }
`;
export const getBankBranchDescriptionByIfscGQL = gql`
    query getBankBranchDescriptionByIfsc($ifsc: String!) {
        getBankBranchDescriptionByIfsc(ifsc: $ifsc)
    }
`;

export const addOrUpdateCompanyAddressAndGstNumberGQL = gql`
  mutation addOrUpdateCompanyAddressAndGstNumber(
    $companyId: String!
    $addressLine1: String
    $addressLine2: String
    $city: String
    $state: String
    $pincode: String
    $gstNo: String
  ) {
    addOrUpdateCompanyAddressAndGstNumber(input: {
        companyId: $companyId
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        state: $state
        pincode: $pincode
        gstNo: $gstNo
      })
  }
`
export const sanctionNewCreditLimitForPbiGQL = gql`
mutation sanctionNewCreditLimitForPbi(
  $input: SanctionNewCreditLimitForPbiInput
){
  sanctionNewCreditLimitForPbi(
    input: $input
  ){
    id,
    creditLimit
    initiatedAt
    approvedAt
    monthsApprovedFor
    nextReviewDueDate
    updatedAt
    pbiId
  }
}
`
export const updateSanctionedCreditLimitOfPbiGQL = gql`
mutation updateSanctionedCreditLimitOfPbi(
  $sanctionedCreditLimitId:String!,
  $monthsApprovedFor:Int!
  $creditLimit:Int!
  ){
  updateSanctionedCreditLimitOfPbi(
    input: {
      id: $sanctionedCreditLimitId, 
      creditLimitSanctionAttributes: {
        monthsApprovedFor: $monthsApprovedFor,
        creditLimit: $creditLimit
      }
    }) {
    id,
    creditLimit
    initiatedAt
    approvedAt
    monthsApprovedFor
    nextReviewDueDate
    updatedAt
    pbiId
  }
}
`

export const addBankGQL = gql`
  mutation addBank(
    $company: String!
    $accountName: String!
    $accountNumber: String!
    $mobileNo: String!
    $ifsc: String!
    $bankName: String!
  ) {
    addCompanyBeneficiary(bankAddData: {
        company: $company
        accountName: $accountName
        accountNumber: $accountNumber
        mobileNo: $mobileNo
        ifsc: $ifsc
        bankName: $bankName
      })
  }
`;

export const onboardCompanyWithPayLaterProductsBundleGQL = gql`
  mutation onboardCompanyWithPayLaterProductsBundle(
    $companyId: String!
    $productsBundleId: String!
    $paymentBeneficiaryId: String!
    $companyLimit: Float!
    $nameOnCard: String
  ) {
  onboardCompanyWithPayLaterProductsBundle(input: {
    companyId: $companyId
    productsBundleId: $productsBundleId
    paymentBeneficiaryId: $paymentBeneficiaryId,
    companyLimit: $companyLimit,
    nameOnCard:$nameOnCard
  })
}
`;

export const onboardCompanyWithNonPrepaidProductsBundleGQL = gql`
  mutation onboardCompanyWithNonPrepaidProductsBundle(
    $companyId: String!
    $productsBundleId: String!
    $nameOnCard: String!
    $companyLimit: Float!
    $nonCardTxnCompanyLimit: Float!
    $monthsApprovedFor: Int!
    $accountManagerUserId: String!
  ) {
  onboardCompanyWithNonPrepaidProductsBundle(input: {
    companyId: $companyId
    productsBundleId: $productsBundleId
    companyLimit: $companyLimit,
    monthsApprovedFor: $monthsApprovedFor,
    accountManagerUserId: $accountManagerUserId
    nonCardTxnCompanyLimit: $nonCardTxnCompanyLimit
    nameOnCard:$nameOnCard
  })
}
`;

export const issueAdditionalProductsBundleNonPrepaidGQL = gql`
mutation issueAdditionalProductsBundleNonPrepaid(
  $companyId: String!
  $productsBundleId: String!
  $companyLimit: Float!
  $monthsApprovedFor: Int!
  $nonCardTxnCompanyLimit: Float!
){
  issueAdditionalProductsBundleNonPrepaid(input:{
    companyId:  $companyId
    productsBundleId: $productsBundleId
    companyLimit:$companyLimit
    monthsApprovedFor:$monthsApprovedFor
    nonCardTxnCompanyLimit: $nonCardTxnCompanyLimit
  })
  {
    id
  }
}
`

export const onboardCompanyWithPrepaidProductsBundleGQL = gql`
  mutation onboardCompanyWithPrepaidProductsBundle(
    $companyId: String!
    $productsBundleId: String!
    $nameOnCard: String!
    $accountManagerUserId: String!
  ){
  onboardCompanyWithPrepaidProductsBundle(input: {
    companyId: $companyId
    productsBundleId: $productsBundleId
    accountManagerUserId: $accountManagerUserId
    nameOnCard:$nameOnCard
  })
}
`;


// ## for monthly statement Rule
export const saveRepaymentAutoDebitRuleStatementDueGQL = gql`
  mutation saveRepaymentAutoDebitRuleStatementDue($pbiId:String!) {
      saveRepaymentAutoDebitRuleStatementDue(input: {
      pbiId:$pbiId
    })
  } 
`;

//  ## for fixed interval Rule
export const saveRepaymentAutoDebitFixedIntervalRuleGQL = gql`
  mutation saveRepaymentAutoDebitFixedIntervalRule($pbiId:String!,$chargeCalculationMethod:AutoDebitChargeCalculationMethod!,$frequencyOfFixedIntervalCharge:FixedIntervalAutoDebitChargeFrequency!){
    saveRepaymentAutoDebitFixedIntervalRule(input: {
        pbiId: $pbiId
        chargeCalculationMethod: $chargeCalculationMethod
        frequencyOfFixedIntervalCharge: $frequencyOfFixedIntervalCharge
    })
  }
`;

// ## for NACH
// # mandateAmountDeterminant: MAX_ALLOWED_BY_AUTO_DEBIT_METHOD
// mandateAmountDeterminant: SAME_AS_COMPANY_LIMIT
// # specifiedMandateAmount: 1500000
// # mandateAmountDeterminant: SPECIFIED
export const initiateRepaymentAutoDebitAuthorizationGQL = gql`
mutation initiateRepaymentAutoDebitAuthorization(
  $companyId:String!,
  $authorizingCompanyAdminEmail:String!,
  $autoDebitMethod:AutoDebitMethod!,
  $mandateAmountDeterminant:MandateAmountDeterminant!,
  $specifiedMandateAmount:Float,
  $beneficiaryName:String!,
  $accountNumber:String!,
  $accountType:BankAccountType!,
  $ifsc:String!,
  ){

  initiateRepaymentAutoDebitAuthorization(input: {
      companyId:$companyId
      authorizingCompanyAdminEmail:$authorizingCompanyAdminEmail
      autoDebitMethod: $autoDebitMethod
      specifiedMandateAmount: $specifiedMandateAmount
      mandateAmountDeterminant: $mandateAmountDeterminant
      ignoreAutoDebitRulesForPbi: false
      bankDetails: {
          beneficiaryName: $beneficiaryName
          accountNumber: $accountNumber
          accountType: $accountType
          ifsc: $ifsc
      }
    })
  }
`;
export const initiateRepaymentAutoDebitAuthorizationEmandateGQL = gql`
mutation initiateRepaymentAutoDebitAuthorization(
  $companyId:String!,
  $authorizingCompanyAdminEmail:String!,
  $autoDebitMethod:AutoDebitMethod!,
  $specifiedMandateAmount:Float!,
  $mandateAmountDeterminant:MandateAmountDeterminant!
  ){
  initiateRepaymentAutoDebitAuthorization(input: {
      companyId:$companyId
      authorizingCompanyAdminEmail:$authorizingCompanyAdminEmail
      specifiedMandateAmount: $specifiedMandateAmount
      autoDebitMethod: $autoDebitMethod
      mandateAmountDeterminant: $mandateAmountDeterminant
      ignoreAutoDebitRulesForPbi: false
    })
  }
`;

export const issueAdditionalProductsBundlePrepaidGQL = gql`
  mutation issueAdditionalProductsBundlePrepaid(
    $companyId: String!
    $productsBundleId: String!
    $nameOnCard: String!
  ) {
  issueAdditionalProductsBundlePrepaid(input: {
 		companyId:  $companyId
    productsBundleId: $productsBundleId
    nameOnCard:$nameOnCard
  }) {
    id
    brandName
  }
}
`;

export const getProductsBundlesGQL = gql`
  query getProductsBundles($excludeIfOnlyForAdditionalIssuance: Boolean) {
    getProductsBundles(excludeIfOnlyForAdditionalIssuance: $excludeIfOnlyForAdditionalIssuance) {
      id
      description
      displayName
      cardIntegration {
        id
        description
        printCompanyNameOnCard
        availableForNewCardIssuance
        cardNetwork
        tenant
      }
      productEnabled {
       cards
       loans
       prepaidCards
       payLater
      }
    }
  }
`;

export const getListOfAccountManagersGQL = gql`
query getListOfAccountManagers {
  getListOfAccountManagers {
    fullName
    displayName
    firstName
    lastName
    id
  }
}
`

export const getStateQueryGQL = gql`
  query getAllStates {
    getAllState {
      id
      name
      isActive
    }
  }
`;

export const getCityByStateGQL = gql`
  query getCitybyState($stateId: Int) {
    getCityByState(stateId: $stateId) {
      id
      name
    }
  }
`;


export const waitlistedCompaniesForManagementGQL = gql`
  query waitlistedCompaniesForManagement(
    $page: Int!,
    $limit: Int!
  ) {
    waitlistedCompaniesForManagement(
      pagination: {page: $page, limit: $limit}
    ) {
      items {
        id
        brandName
        registeredName
        gstNo
        serviceTaxNo
        borrowerCategory
        vatTinNo
        emailId
        phoneNo
        cin
        website
        createdBy
        updatedBy
        createdAt
        updatedAt
        companyBalance
        companyLimit
        borrowerCategory
        acceptCreditLimit
        companyData {
          isManualEntry
        }
        productsBundleIssuance {
          id
          sharedPayLaterBeneficiaryId
          companyProductEnabled {
            prepaidCards
          }
        }
      },

      meta {
        totalItems
      }
    }
  }
`;

export const updateCompanyBorrowerCategoryGQL = gql`
    mutation updateCompanyBorrowerCategory($companyId: String!, $borrowerCategory: BorrowerCategory!) {
    updateCompanyBorrowerCategory(input: {companyId: $companyId, borrowerCategory: $borrowerCategory})
  }
`;

export const inviteUserToMyCompanyGQL = gql`
  mutation inviteUserToMyCompany(
      $firstName: String
      $lastName: String
      $emailId: String!
      $mobileNo: String
      $grantCompanyAdminRole: Boolean!
      $grantAccountantRole: Boolean!
      
      $pbiId: String!
      $cardForm: CardForm
      $fundsSource: CardFundsSource!
      $initialFundAllocation: Float
      $monthlyLimit: Float
      $dailyLimit: Float
      $shouldIssueCard: Boolean!
      $cardIntegrationId: String 
      $quickPayLimit: AddUserQuickPayLimitInput
    ) {
    inviteUserToMyCompany(input: {
      employeeInput: {
        firstName: $firstName
        lastName: $lastName
        emailId: $emailId
        mobileNo: $mobileNo
        grantCompanyAdminRole: $grantCompanyAdminRole
        grantAccountantRole: $grantAccountantRole
      }
      cardIssuanceInput: {
        pbiId: $pbiId
        cardForm: $cardForm
        fundsSource: $fundsSource
        initialFundAllocation: $initialFundAllocation
        monthlyLimit: $monthlyLimit
        dailyLimit: $dailyLimit
        shouldIssueCard: $shouldIssueCard
        cardIntegrationId: $cardIntegrationId
      }
      quickPayLimit: $quickPayLimit
    }) {
      id
    }
  }
`;

export const closeKodoAccountOfCompanyGQL = gql`
  mutation closeKodoAccountOfCompany(
    $ignoreDueAmount: Boolean!
    $ignoreMultiplePbi: Boolean!
    $companyId: String!
  ) {
    closeKodoAccountOfCompany(input: {
      ignoreDueAmount: $ignoreDueAmount,
      ignoreMultiplePbi: $ignoreMultiplePbi,
      companyId: $companyId
    }
  ) }
`
export const setupProductFeatureFeeExclusionGQL = gql`
  mutation setupProductFeatureFeeExclusion(
    $excludeFromCharges: Boolean!
    $pricingItem: PricingItem!
    $pbiId: ID
    $companyId: ID
  ) {
    setupProductFeatureFeeExclusion(input: {
      pricingItem: $pricingItem,
      excludeFromCharges: $excludeFromCharges,
      companyId: $companyId,
      pbiId: $pbiId
    }
  ) }
`
export const saveProductFeatureFeeCompanyConfigGQL = gql`
  mutation saveProductFeatureFeeCompanyConfig(
    $isActive: Boolean!
    $companyId: String!
    $pbiId: String
    $productFeatureFeeConfigId: String!
    $priceListing: PriceListingInput!
    $applicableFromDate: String
    $applicableTillDate: String
    $priority: Int
    $deactivationDate: Date
    $deactivationReason: String
  ) {
    saveProductFeatureFeeCompanyConfig(input: {
     isActive: $isActive
     companyId: $companyId
     pbiId: $pbiId
     productFeatureFeeConfigId: $productFeatureFeeConfigId
     priceListing: $priceListing
     applicableFromDate: $applicableFromDate
     applicableTillDate: $applicableTillDate
     priority: $priority
     deactivationDate: $deactivationDate
     deactivationReason: $deactivationReason
    }) {
      id
      isActive
      companyId
      pbiId
      productFeatureFeeConfigId
      priceListing {
          chargeCalcMethod
          gstOnFeePercentage
          chargeTrigger
          chargeCalc {
              seedValue
              multiple
              upperCap
          }
      }
      applicableFromDate
      applicableTillDate
      priority
      deactivationDate
      deactivationReason
      lastUpdateNotes
    }
  }
`

export const getProductFeatureFeeConfigGQL = gql`
  query getProductFeatureFeeConfig(
    $pricingItem: PricingItem!
  ) {
    getProductFeatureFeeConfig(input: {
     productsBundleId: null
     pricingItem: $pricingItem
    }) {
      id
      priceListing {
          chargeCalcMethod
          gstOnFeePercentage
          chargeTrigger
          chargeCalc {
              seedValue
              upperCap
          }
      }
    }
  }
`

export const getProductFeatureFeeCompanyConfigGQL = gql`
  query getProductFeatureFeeCompanyConfig($input: ProductFeatureFeeCompanyConfigInfoInput!) {
    getProductFeatureFeeCompanyConfig(input: $input) {
      id
      isActive
      companyId
      pbiId
      productFeatureFeeConfigId
          priceListing {
        chargeCalcMethod
        gstOnFeePercentage
        chargeTrigger
              chargeCalc {
          seedValue
          multiple
          upperCap
        }
      }
      applicableFromDate
      applicableTillDate
      priority
      deactivationDate
      deactivationReason
      lastUpdateNotes
      productFeatureFeeExclusion {
        id
        productFeatureFeeConfigId
        companyId
        pbiId
        createdAt
      }
    }
  }
`
