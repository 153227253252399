import { gql } from '@apollo/client';

export const ensureKodoBankAccountForCompanyGQL = gql`
  mutation ensureKodoBankAccountForCompany(
    $ownerCompanyId: String!
    $removeFundingBankAccount: Boolean
    $fundingBankAccountValidationRequestId: String!
    $bankAccountId: String
  ) {
    ensureKodoBankAccountForCompany(
      removeFundingBankAccount:$removeFundingBankAccount
      fundingBankAccountValidationRequestId:$fundingBankAccountValidationRequestId
      input:{ownerCompanyId: $ownerCompanyId, bankAccountId: $bankAccountId}
    ) {
      id
      accountNo
      ifsc
      balance
    }
  }
`;
export const fundingBankAccountForCompanyGQL = gql`
  query fundingBankAccountForCompany(
    $includeDeleted: Boolean
    $companyId: String!
  ) {
    fundingBankAccountForCompany(
      companyId: $companyId
      includeDeleted: $includeDeleted
    ) {
      fundingBankAccountForCompanyOutput {
        id
        accountName
        accountNumber
        ifsc
        lastValidationRequestId
        validatedAt
        isDeleted
        deletedBy
        deletedOn
        addedBy
        addedOn
        bankAccount {
          id
          serviceProviderIntegration {
            id
            key
            serviceProviderIntegrator {
              id
              key
              name
            }
          }
        }
      }
      maximumAuthorizedAccountAllowedForVaFunding
      totalActiveFundingBankAccountForCompany
    }
  }
`;

export const allVirtualAccountsForCompanyViaInternalPortalPaginatedGQL = gql`
  query allVirtualAccountsForCompanyViaInternalPortalPaginated(
    $companyId: String!
    $pagination: PaginationOptions!
    $excludeNonActiveBankAccounts: Boolean
  ) {
    allVirtualAccountsForCompanyViaInternalPortalPaginated(
      companyId: $companyId
      pagination: $pagination
      excludeNonActiveBankAccounts: $excludeNonActiveBankAccounts
    ) {
      items {
        id
        accountNo
        nickname
        ifsc
        upiHandle
        balance
        balanceLastUpdatedAt
        isActive
        activatedAt
        activatedBy {
          fullName
          displayName
        }
        serviceProviderIntegration {
          key
          serviceProviderIntegrator {
            key
            name
          }
        }
      }
      meta {
        totalItems
      }
    }
  }
`;

export const deactivateOrActivateVaForCompanyGQL = gql`
mutation deactivateOrActivateVaForCompany(
  $bankAccountId: String!
  $isActive: Boolean!
) {
  deactivateOrActivateVaForCompany(
    isActive: $isActive,
    bankAccountId: $bankAccountId,
  )
}
`;

export const AccountFundingGQL = {
  ensureKodoBankAccountForCompany: ensureKodoBankAccountForCompanyGQL,
  fundingBankAccountForCompany: fundingBankAccountForCompanyGQL,
  allVirtualAccountsForCompanyViaInternalPortalPaginated: allVirtualAccountsForCompanyViaInternalPortalPaginatedGQL,
  deactivateOrActivateVaForCompany: deactivateOrActivateVaForCompanyGQL
}
