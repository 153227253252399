enum CardNetwork {
    Mastercard = "Mastercard",
    Visa = "Visa",
    RuPay = "RuPay"
}
export enum CardFundsSource {
    ALLOCATED_TO_CARD = "ALLOCATED_TO_CARD",
    SHARED_WITHIN_PBI = "SHARED_WITHIN_PBI",
}
export interface IState {
    id: number;
    value?: number;
    isActive: string;
    name: string;
    label: string;
}

export interface IProductEnabled {
    cards: boolean;
    loans: boolean;
    prepaidCards: boolean;
    payLater: boolean;
}
export interface CardIntegration {
    id: string
    description: string
    cardNetwork: CardNetwork
    tenant: string
    printCompanyNameOnCard: boolean
    availableForNewCardIssuance: boolean
    issuer: CardIssuer
    requestFromPciWidgetForTxnDetails: boolean
    requestFromPciWidgetForSetPin: boolean
    requestFromPciWidgetForCardDetails: boolean
    isProgramTempPaused: Boolean
    isProgramStopped: Boolean
    userMonthlyTransactionLimit: number
    userYearlyTransactionLimit: number
    cardsAllowedPerWallet: number
    hasRestrictionForCard: Boolean
}
export interface IProductsBundle {
    id: string;
    displayName: string;
    description: string;
    cardIntegration: CardIntegration;
    cardIntegrations: [CardIntegration];
    productEnabled: IProductEnabled;
    productsBundle: IProductsBundle;
}
export interface IRepaymentAutoDebitAuth {
    failureReason: string
    id: string
    lastUpdateNotes: string
    mandateMaxAmountPerCharge: number
    method: string
    rzpCustomerId: string
    rzpInvoiceId: string
    rzpMandateRegUrl: string
    rzpOrderId: string
    rzpTokenId: string
    status: string
}

export enum AutoDebitChargeCalculationMethod {
    DUE_BASED_ON_ACTIVE_STATEMENT = 'DUE_BASED_ON_ACTIVE_STATEMENT',
    DUE_BASED_ON_LATEST_TXN = 'DUE_BASED_ON_LATEST_TXN',
    FIXED_AMOUNT = 'FIXED_AMOUNT',
}
export enum FixedIntervalAutoDebitChargeFrequency {
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    FORTNIGHTLY = 'FORTNIGHTLY',
}
export interface IRepaymentAutoDebitRule {
    id: string
    amountForFixedChargeCalculationMethod: number
    chargeCalculationMethod: AutoDebitChargeCalculationMethod
    frequencyOfFixedIntervalCharge: FixedIntervalAutoDebitChargeFrequency
}

export interface ISanctionedCredit {
    id: string;
    creditLimit: number;
    monthsApprovedFor: number;
}
export interface IPBIMappings {
    id: string;
    pbiId: string;
    pbi: IProductsBundle;
}
export interface IUserAddress {
    id: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: {
        id: number;
        name: string;
    },
    state: {
        id: number;
        name: string;
    },
    cityName: string;
    stateName: string;
    pincode: number;
}
export interface IUser {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
    displayName: string;
    emailId: string;
    emailVerified: false,
    panNo: string;
    mobileNo: string;
    mobileVerified: false,
    profilePicture: string;
    gender: string;
    kycStatus: null,
    rolesToDisplay: Array<string>
    kycDocumentsUploadedForCardholder: 0,
    userAccounts: Array<UserAccount>;
    company: ICompany
    dob: string;
    deliveryAddress: any;
    addresses: Array<IUserAddress>
    dailyLimit: any;
    monthlyLimit: any;
    card: [ICard]
    version: 1,
    m2pCustomerId: string;
    kycMode: any;
    pbiMappings: Array<IPBIMappings>;
    expectedKycModeForIssuingCard: string;
}
export interface UserCompanyDetails {
    pendingPaymentCount: number,
    userCompanyList: Array<IUser>
}
export interface UserAccount {
    id: string
    status: UserAccountStatus
    roleNames: Array<string>
    user: IUser
    companyMappingList: Array<UserAccountCompanyMapping>
}

export interface UserAccountCompanyMapping {
    id: string
    userAccountId: string
    companyId: string
    company: ICompany
}
export enum UserAccountStatus {
    NOT_ACTIVATED_YET = 'NOT_ACTIVATED_YET',
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED',
}

export interface ICard {
    id: string
    label: string
    pbiId: string
    pbi: ICompanyPBI
    fundsSource: CardFundsSource
    user: IUser
    kit: Kit
    issuedDate: Date
    issueDateForVirtualForm: Date
    issueDateForPhysicalForm: Date
    activationNotificationSentAt: Date
    cardActivatedByIntegratorAt: Date
    physicalFormRequestedAt: Date
    cardActivatedInternallyAt: Date
    monthlyLimit: number
    monthlyLimitUnusedCurrentMonthIst: number
    effectivelyAvailableLimitCurrentMonthIst: number
    netExpenseCurrentMonthIst: number
    dailyLimit: number
    dailyLimitUnusedTodayIst: number
    effectivelyAvailableLimitTodayIst: number
    netExpenseTodayIst: number
    endNo: string
    expiryDate: string
    allowContactless: boolean
    isContactlessAvailable: boolean
    isActivated: boolean
    isLocked: boolean
    isLockedAtCardIntegrator: boolean
    isBlocked: boolean
    isDeleted: boolean
    createdAt: Date
    updatedAt: Date
    createdBy: IUser
    updatedBy: IUser
    allocatedFundsUnused: number
}
export interface Kit {
    id: string
    kitNo: string
    card: ICard
    isIssued: boolean
    issuedTo: IUser
    cardType: string
    networkType: string
    isActive: boolean
    otp: number
    isDeleted: boolean
    createdAt: Date
    updatedAt: Date
    createdBy: IUser
    updatedBy: IUser
    cardIntegration: CardIntegration
}

export interface CardIssuer {
    id: string
    key: string
}
export interface ICompanyPBI {
    id: string;
    value?: string;
    cardIntegration: CardIntegration
    label?: string;
    displayName: string;
    description: string;
    activatedAt: any;
    creditLimit: number;
    availableLimit: number;
    productEnabled: IProductEnabled;
    cards: [ICard];
    companyProductEnabled: any;
    productsBundle: IProductsBundle;
    repaymentAutoDebitAuth: IRepaymentAutoDebitAuth;
    repaymentAutoDebitRule: IRepaymentAutoDebitRule;
    sanctionedCreditLimit: ISanctionedCredit;
}
export interface CompanyDetailType {
    data: Array<ICompany>;
    isLoading: boolean;
}
export enum KycStatus {
    // internal:
    NOT_SUBMITTED = 'NOT_SUBMITTED',
    SUBMITTED = 'SUBMITTED',
    SUBMITTED_AND_VERIFIED = 'SUBMITTED_AND_VERIFIED',
    ERROR = 'ERROR',
    // M2P:
    COMPLETED = 'COMPLETED', // Cleared Bank's 2nd Level Checker. On reaching this level, card gets activated.
    VERIFIED = 'VERIFIED', // Cleared Bank's 1st Level Checker
    PENDING = 'PENDING', // Docs uploaded and yet to be examined
    FAILED = 'FAILED', // Rejected by Bank
}

const UPI_REGEX = /^[^\s@]+?@[^\s@]+$/;
const EMAIL_REGEX = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/; // Note: Use ReactiveFormValidator.emailValidator() in case of Reactive forms or isEmail (import isEmail from 'validator/es/lib/isEmail';) for other cases
const EMAIL_CASE_INSENSITIVE_REGEX = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]/;
const MOBILE_NO_REGEX = /^[0-9]+$/;
const INDIAN_MOBILE_NO_REGEX = /^[6-9]{1}[0-9]{9}$/;
const PINCODE_REGEX = /^[1-9][0-9]{5}$/;
const PASSCODE_REGEX = /^[0-9]{6}$/;
const AADHAAR_REGEX = /^[0-9]{12}$/;
const ADDRESS_REGEX = /^[\d\sa-zA-ZÀ-ú-/\,]+$/;
const TEXT_WITHOUT_DOUBLE_SPACE_REGEX = /^(?!.*  ).*$/;
const TEXT_WITHOUT_DOUBLE_QUOTES_REGEX = /^(?!.*").*$/;
const ALPHABETS_WITHOUT_CONSECUTIVE_DOUBLE_SPACES_REGEX = /^[a-zA-Z]+( [a-zA-Z]+)*$/;
const ALPHA_NUMERIC_WO_SPACE_REGEX = /^[a-zA-Z0-9]*$/;
const PERSON_NAME_REGEX = /^[\d\s-’'.a-zA-ZÀ-ú\/]*$/;
const BANK_ACCOUNT_NUMBER_REGEX = /^\d+$/;
const ifsc = /^[a-zA-Z0-9]{2,15}$/;
const PAN_NO_REGEX = /^[a-zA-Z]{5}\d{4}[a-zA-Z]{1}$/;
const ALPHA_NUMERIC_WT_SPACE_REGEX = /[a-zA-Z0-9 ]*$/;
const BENEFICIARY_NAME_REGEX = PERSON_NAME_REGEX; // /^[\da-zA-Z ]*$/;
const AMOUNT_WT_DCIMAL_REGEX = /^[-+]?(?:\d+(?:\.\d*)?|\.\d+)?$/;

export const InputValidatorRegex = {
    EMAIL: EMAIL_REGEX,
    EMAIL_CASE_INSENSITIVE: EMAIL_CASE_INSENSITIVE_REGEX,
    MOBILE_NO: MOBILE_NO_REGEX,
    INDIAN_MOBILE_NO: INDIAN_MOBILE_NO_REGEX,
    PERSON_NAME: PERSON_NAME_REGEX,
    IFSC: ifsc,
    BANK_ACCOUNT_NUMBER: BANK_ACCOUNT_NUMBER_REGEX,
    PAN_NO: PAN_NO_REGEX,
    ALPHA_NUMERIC_WT_SPACE: ALPHA_NUMERIC_WT_SPACE_REGEX,
    ALPHA_NUMERIC_WO_SPACE: ALPHA_NUMERIC_WO_SPACE_REGEX,
    BENEFICIARY_NAME_REGEX,
    PINCODE: PINCODE_REGEX,
    PASSCODE: PASSCODE_REGEX,
    UPI: UPI_REGEX,
    OTP: PASSCODE_REGEX,
    AADHAAR: AADHAAR_REGEX,
    ADDRESS: ADDRESS_REGEX,
    TEXT_WITHOUT_DOUBLE_SPACE: TEXT_WITHOUT_DOUBLE_SPACE_REGEX,
    TEXT_WITHOUT_DOUBLE_QUOTES: TEXT_WITHOUT_DOUBLE_QUOTES_REGEX,
    ALPHABETS_WITHOUT_CONSECUTIVE_DOUBLE_SPACES: ALPHABETS_WITHOUT_CONSECUTIVE_DOUBLE_SPACES_REGEX,
    AMOUNT_WT_DCIMAL: AMOUNT_WT_DCIMAL_REGEX,
};
export interface ICompany {
    primaryAccountManagerUser: any;
    pbisPendingActivation: Array<ICompanyPBI>
    productsBundleIssuances: Array<ICompanyPBI>
    repaymentAutoDebitAuth: IRepaymentAutoDebitAuth;
    productsBundleIssuance: any,

    nonDeletedPBIsSortedByDate: Array<ICompanyPBI>,
    bank: Array<ICompanyPBI>,
    companyLimit: number;
    companyBalance: string;

    id: string;
    companyData: CompanyData;
    label: any;
    options: any;
    brandName: string;
    acceptCreditLimit: boolean;
    accessToken: string;
    borrowerCategory: string;
    borrowerUuid: string;
    cin: string;
    companyCategory: string;
    companyCustomerId: string;
    companyDataId: number;
    companyStep: { joinkodo: true, bankStatement: false, gstStatement: false, bankDetails: false, waitList: false }
    companyUuid: string;
    companyVerification: string;
    copyOfId: any;
    createdAt: string;
    data: any;
    emailId: string;
    firstCardActivatedAt: string;
    ftlAuthResult: string;
    ftlPassword: string;
    ftlUserId: any;
    ftlUsername: string;
    gstNo: string;
    isActive: boolean;
    disableStatementGeneration: boolean;
    isDeleted: boolean;
    isNachActive: boolean;
    lastUpdateNotes: string;
    limitApprovalLoan: string;
    nameOnCard: string;
    panNo: string;
    phoneNo: string;
    displayName: string;
    registeredName: string;
    serviceTaxNo: string;
    updatedAt: string;
    vatTinNo: string;
    version: number;
    website: string;
    featureFlags?: {
        productFeatureApprovalRequest?: boolean;
        dynamicWorkflow?: boolean;
    };
    companySettings?: {
        id?: string;
        settings?: {
            isProjectRequiredForKodoPay?: boolean;
            isApprovalRequestReasonRequired?: boolean;
            isDepartmentRequiredForApprovalWorkflow?: boolean;
            isSubDepartmentRequiredForApprovalWorkflow?: boolean;
            isDesignationRequiredForApprovalWorkflow?: boolean;
            isEmployeeGradeRequiredForApprovalWorkflow?: boolean;
            isEmployeeCategoryRequiredForApprovalWorkflow?: boolean;
            isLocationRequiredForApprovalWorkflow?: boolean;
            isProjectRequiredForApprovalWorkflow?: boolean;
            isReportingManagerRequiredForApprovalWorkflow?: boolean;
        }
    };
}
export interface CompanyData {
    id: number
    authorizedCapital: string
    email: string
    legalName: string
    efilingStatus: string
    incorporationDate: string
    addressLine1: string
    addressLine2: string
    state: string
    stateName: string
    city: string
    cityName: string
    pincode: string
    gstNo: string
    serviceTaxNo: string
    vatTinNo: string
    phoneNo: string
    cin: string
    isDeleted: Boolean
    createdAt: Date
    updatedAt: Date
    borrowerCategory: string
    acceptCreditLimit: Boolean
    directors: Array<DirectorsData>
    company: ICompany
    isManualEntry: Boolean
    classification: string
    status: string
    onboardStatus: string
}
export interface DirectorMobileDet {
    id: number
    mobile: string
    deleted: boolean
}
export interface DirectorAddressDet {
    addressLine1: string
    addressLine2: string
    state: string
    city: string
    pincode: string
}
export interface DirectorsData {
    address: DirectorAddressDet
    mobile: Array<DirectorMobileDet>
    directorCreditReports: JSON
    isSelected: boolean
    dob: string
    firstName: string
    middleName: string
    lastName: string
    isEdited: Boolean
    id: number
    index: number
    name: string
    din: string
    birthDate: string
    addressLine1: string
    addressLine2: string
    state: string
    city: string
    pincode: string
}
export interface IOptionGroup {
    value: string;
    label: string | React.ReactNode;
}
export interface IOptions {
    label: string | React.ReactNode;
    options: IOptionGroup[];
}
export enum CompanyRoles {
    companyAdmin = 'Company Admin',
    accountant = 'Accountant',
    cardholder = 'Cardholder',
}

export enum CardLimit {
    monthlyLimit = 'Monthly Limit',
    dailyLimit = 'Daily Limit',
}
export enum MandateAmount {
    SAME_AS_COMPANY_LIMIT = 'SAME AS COMPANY LIMIT',
    MAX_ALLOWED_BY_AUTO_DEBIT_METHOD = 'MAX ALLOWED BY AUTO DEBIT METHOD',
    SPECIFIED = 'SPECIFIED',
}
export enum DebitMethod {
    NACH = 'NACH',
    EMANDATE = 'e-Mandate',
    EMANDATE_CAP = 'EMANDATE',
}
export enum FrequencyList {
    MONTHLY = 'Monthly',
    WEEKLY = 'Weekly',
    DAILY = 'Daily',
}
export enum KycType {
    paper = 'PAPER',
    electronic = 'ELECTRONIC',
}
export enum ProductBundle {
    pbKodoDailyCredit = 'Kodo Daily Credit',
    pbKodoCredit = 'Kodo Credit',
    pbKodoPayLater = 'Kodo Pay Later',
    pbKodoPrepaid = 'Kodo Prepaid',
    pbKodoTranscorpPrepaid = 'Kodo-Transcorp Prepaid',
    pbKodoLivQuikPrepaid = 'Kodo LIVQUIK Prepaid',
    pbKodoPayOnCredit = 'Kodo Pay On Credit',
}
export enum ProductBundle {
    KODO_CREDIT = 'Kodo Credit',
    KODO_DAILY_CREDIT = 'Kodo Daily Credit',
    KODO_PAY_LATER = 'Kodo Pay Later',
    KODO_PREPAID = 'SBM Cash',
    KODO_LIVQUIK_PREPAID = 'LQ Prepaid',
    // KODO_CREDIT_OLD = 'Kodo Credit (archived)',
}


export interface Bank {
    id: string;
    accountName: string;
    accountNumber: string;
    mobileNo: string;
    ifsc: string;
    bankName: string;
}
export interface VaFundingBank {
    id: string;
    accountName: string;
    accountNumber: string;
    mobileNo: string;
    ifsc: string;
    addedBy: string;
    addedOn: string;
    lastValidationRequestId: string
    validatedAt: string
    isDeleted: boolean
    deletedBy: string
    deletedOn: string
    isActive: boolean
    bankAccount: {
        id: string,
        serviceProviderIntegration: {
            id: string,
            key: string,
            serviceProviderIntegrator: {
                id: string,
                key: string,
                name: string,
            },
        },
    }
}
export interface FilterCriteria {
    pbiId: string
    companyId: string
    merchantId: string
    mcc: string
    txnMerchantId: string
    merchantNameNormalized: string
    onlySubscriptionTransactions: boolean
    countOfTopCategories: number
    fromDate: string
    toDate: string
    searchKeyword: string
    fromAmount: number
    toAmount: number
    includeNegativeExpenses: boolean
    includeRootTransaction: boolean
    showWithoutReceiptOnly: boolean
    pageSize: number
    pageNumber: number
    includeExpenseFlaggedAsPersonal: boolean
    includePersonalExpenseOnly: boolean

}

export interface UserDetails {
    id?: string;
    company?: string;
}
export const UserTitle = [
    {
        label: "Mr.",
        value: "Mr.",
    },
    {
        label: "Ms.",
        value: "Ms.",
    },
]
export const IdType = [
    {
        label: "GST",
        value: "GST",
    },
]
export const UserGender = [
    {
        label: "Male",
        value: "MALE",
    },
    {
        label: "Female",
        value: "FEMALE",
    },
]
export interface M2pCorporateOnboardingData {
    title: string,
    firstName: string,
    lastName: string,
    contactNo: string,
    emailAddress: string,
    gender: string,
    specialDate: Date, // birthday
    address: string,
    address2: string,
    city: string,
    state: string,
    country: string,
    pincode: string,
    idType: string,
    idNumber: string,
    countryofIssue: string,
    dependent: string,
    idExpiry?: string,
    kycRefNo?: string,
    kycStatus?: string,
    countryCode?: string,
    addressDto?: string,
    contactNo1?: string,
    emailAddress1?: string,
    emailAddress2?: string
}

export interface PriceListing {
    chargeCalcMethod: PricingChargeCalculationMethod
    chargeCalc: PricingChargeCalculationOutput
    gstOnFeePercentage: number
    chargeTrigger: PricingChargeTrigger
}
export interface PricingChargeCalculationOutput {
    seedValue: number
    multiple: number
    upperCap: number
}
export interface ProductFeatureFeeConfig {
    id: string,
    priceListing: PriceListing
}
export interface ProductFeatureFeeExclusion {
    id: string,
    productFeatureFeeConfigId: string,
    companyId: string,
    pbiId: string,
    createdAt: Date
}
export interface ProductFeatureFeeCompanyConfig {
    id: string,
    isActive: boolean,
    companyId: string,
    pbiId: string,
    productFeatureFeeConfigId: string,
    priceListing: PriceListing,
    applicableFromDate: string,
    applicableTillDate: string,
    priority: number,
    deactivationDate: string,
    deactivationReason: string,
    lastUpdateNotes: string,
    productFeatureFeeExclusion: ProductFeatureFeeExclusion
}

export enum PricingItem {
    PHYSICAL_CARD_ISSUANCE = 'PHYSICAL_CARD_ISSUANCE',
    PHYSICAL_CARD_REPLACEMENT = 'PHYSICAL_CARD_REPLACEMENT',
    ATM_WITHDRAWAL = 'ATM_WITHDRAWAL',
    KODO_VA_TXN_EXCLUDE_LV = 'KODO_VA_TXN_EXCLUDE_LV', // Excludes Listed Vendor
    // PLATFORM_FEE = 'PLATFORM_FEE', // Not required for now
}
export enum PricingChargeTrigger {
    PER_ACTION = 'PER_ACTION',
    PER_MONTH = 'PER_MONTH',
}
export enum PricingChargeCalculationMethod {
    FIXED_AMOUNT = "FIXED_AMOUNT",
    PERCENTAGE = "PERCENTAGE",
    PERCENT_WITH_UPPER_CAP = "PERCENT_WITH_UPPER_CAP",
}